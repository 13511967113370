<!--
 * @Description: 评价管理
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:44:49
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-07-22 18:07:59
-->
<template>
  <el-card class="box">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="商家回复">
        <el-select
          v-model="query.is_answer"
          placeholder=""
          clearable
          style="width: 120px"
        >
          <el-option label="已回复" :value="true"></el-option>
          <el-option label="未回复" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="评价得分">
        <el-select v-model="query.star_score" placeholder="" clearable>
          <el-option
            :label="`${i}星`"
            :value="i"
            v-for="i in 5"
            :key="i"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="评论日期">
        <el-date-picker
          v-model="query.date_list"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border stripe>
      <el-table-column
        label="订单号"
        width="200"
        prop="order.order_no"
      ></el-table-column>
      <el-table-column
        label="商品标题"
        width="240"
        prop="goods_title"
      ></el-table-column>
      <!-- <el-table-column label="数量" width="100">
        <template #default="scope"> x{{ scope.row.order.number }} </template>
      </el-table-column>
      <el-table-column label="金额" width="160">
        <template #default="scope">
          <div>商品总额：￥{{ scope.row.order.decimal_amount }}</div>
          <div>订单总额：￥{{ scope.row.order.decimal_total_amount }}</div>
          <div>运费金额：￥{{ scope.row.order.decimal_shipping_amount }}</div>
        </template>
      </el-table-column> -->
      <el-table-column label="订单状态" width="120" prop="status_text">
      </el-table-column>
      <!-- <el-table-column label="支付状态" width="180">
        <template #default="scope">
          <div>状态：{{ scope.row.order.pay_status_text }}</div>
          <div>时间：{{ scope.row.order.pay_at }}</div>
        </template>
      </el-table-column> -->

      <el-table-column label="用户" prop="member_name" width="160">
        <template #default="scope">
          <span>
            <el-avatar
              :src="scope.row.avatar_pic"
              style="width: 60px; height: 60"
            ></el-avatar>
          </span>
          <span>{{ scope.row.member_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="综合评分"
        prop="star_score"
        width="120"
      ></el-table-column>

      <el-table-column
        label="评论内容"
        prop="comment_content"
        width="260"
      ></el-table-column>
      <el-table-column
        label="创建时间"
        width="180"
        prop="create_at"
      ></el-table-column>

      <el-table-column
        label="回复内容"
        prop="answer"
        width="260"
      ></el-table-column>
      <el-table-column label="操作" fixed="right">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            v-if="scope.row.emp_id == null"
            >回复</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    >
    </el-pagination>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      enums: {},
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      //数据
      tableData: {
        counts: 0,
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.post("seller/v1/goodsComment", this.query).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 展开订单详情
     * @param {*} item
     */
    onDtl(item) {
      console.log(item);
      //   this.current_order_id = item.id;
    },
  },
};
</script>

<style  scoped>
</style>